.custom-input-component {
  background: #ffffff;
  label {
    color: #8e919d;
    font-size: 16px;
    transform: translate(14px, 13px) scale(1);
  }
  label.Mui-focused {
    color: #a4a4a4;
  }
  label.Mui-error {
    color: #ff6f3e;
  }
  label.Mui-disabled {
    color: #b1bed6;
  }

  input {
    color: #212121;
    padding: 13px 10px;
  }
  input.Mui-disabled {
    color: #b1bed6;
  }

  .MuiOutlinedInput-root {
    fieldset {
      border: 1px solid #616882;
    }
    &:hover {
      fieldset {
        border: 1px solid #616882;
      }
    }
    &.Mui-focused fieldset {
      border-color: #5c8de8;
      border-width: 1px;
    }
  }
  .MuiOutlinedInput-root.Mui-error {
    fieldset {
      border-color: #ff6f3e;
    }
    &:hover {
      fieldset {
        border-color: #ff6f3e;
      }
    }
  }
  .MuiOutlinedInput-root.Mui-disabled {
    fieldset {
      border-color: #b1bed6;
    }
    &:hover {
      fieldset {
        border-color: #b1bed6;
      }
    }
  }

  .MuiFormHelperText-root.Mui-error {
    margin: 0;
    font-size: 10px;
    line-height: 14px;
    color: #ff6f3e;
    font-weight: 400;
  }
}
