.location-module {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__title {
    font-size: 24px;
    line-height: 42px;
    font-weight: 600;
    color: #000000;
    margin: 30px 0 45px;
    font-family: Magallanes-Regular, 'Arial', Open Sans, sans-serif;
  }
  .location-form-wrap {
    .location-map {
      max-width: 356px;
      height: 238px;
      &__radius-info {
        margin: -10px 0 0;
        font-size: 10px;
      }
    }
    .location-form {
      max-width: 356px;
      width: 100%;
      margin-top: 15px;
    }
  }
  .location-form {
    &__title {
      font-size: 18px;
      line-height: 42px;
      color: #000000;
      margin: 0 0 14px;
      font-family: Magallanes-Regular, 'Arial', Open Sans, sans-serif;
    }
    .location-form-row {
      margin-bottom: 25px;
    }
    .location-form-row.address-row {
      display: flex;
      justify-content: space-between;
      .location-form-row__item {
        width: 48%;
      }
    }
  }
}
